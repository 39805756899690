import axios from "axios";
import { API_AUTH_URL } from "configs/AppConfig";
import { t } from "i18next";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "constants/AuthConstant";
import { message, notification } from "antd";
import store from "store";
import { signOutSuccess } from "store/slices/authSlice";

const service = axios.create({
  baseURL: API_AUTH_URL,
  timeout: 60000,
});

const PUBLIC_REQUEST_KEY = "public-request";

//  Request interceptor for Refresh the token if it is expired
service.interceptors.request.use(
  (config) => {
    const refreshToken = localStorage.getItem(REFRESH_TOKEN);

    if (!refreshToken && !config.headers[PUBLIC_REQUEST_KEY]) {
      // Dispatch the signOutSuccess action to update the state of the application
      store.dispatch(signOutSuccess());
    }

    return config;
  },
  (error) => {
    // Do something with request error here
    notification.error({
      message: "Error",
    });
    Promise.reject(error);
  }
);

// API response interceptor
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem(ACCESS_TOKEN);
      localStorage.removeItem(REFRESH_TOKEN);

      // Dispatch the signOutSuccess action to update the state of the application
      store.dispatch(signOutSuccess());

      //Display an error message
      message.error(t("response.error.auth"), 3);

      return Promise.reject(error);
    }
  }
);

export default service;
