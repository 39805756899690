import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "constants/AuthConstant";
import {
  COMPANY_ID,
  COMPANY_NAME,
  COMPANY_TYPE,
  FIRST_PRODUCTION_DATE,
  STAGES,
  TODO_LIST,
  EMPLOYEES,
  ASSETS,
} from "constants/CompanyConstant";
import { LOCALE, THEME } from "constants/ThemeConstant";
import {
  BI_ACCESS,
  DEPARTMENT,
  EMAIL,
  FULL_NAME,
  MEASUREMENT_UNITS,
} from "constants/UserConstant";
import UserService from "services/api/UserService";
import AuthService from "services/auth/AuthService";
import store from "store";
import {
  onLocaleChange,
  onSwitchTheme,
  resetTheme,
} from "store/slices/themeSlice";

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  access_token: localStorage.getItem(ACCESS_TOKEN) || null,
  refresh_token: localStorage.getItem(REFRESH_TOKEN) || null,
  companyType: localStorage.getItem(COMPANY_TYPE) || null,
  department: localStorage.getItem(DEPARTMENT) || null,
  biAccess: JSON.parse(localStorage.getItem(BI_ACCESS)) || null,
  stages: JSON.parse(localStorage.getItem(STAGES)) || null,
  todo_list: JSON.parse(localStorage.getItem(TODO_LIST)) || null,
  employees: JSON.parse(localStorage.getItem(EMPLOYEES)) || null,
  assets: JSON.parse(localStorage.getItem(ASSETS)) || null,
};

export const saveUserInfoToLocalStorage = (userInfo) => {
  // Save user's information in local storage
  localStorage.setItem(BI_ACCESS, userInfo.company.bi_access);
  localStorage.setItem(DEPARTMENT, userInfo.department);
  localStorage.setItem(EMAIL, userInfo.email);
  localStorage.setItem(FULL_NAME, userInfo.full_name);
  localStorage.setItem(
    MEASUREMENT_UNITS,
    JSON.stringify(userInfo.preferences.measurement_units)
  );

  // Localization
  const locale =
    userInfo.preferences.language === "Greek"
      ? "el"
      : userInfo.preferences.language === "Deutsch"
      ? "de"
      : "en";
  localStorage.setItem(LOCALE, locale);
  store.dispatch(onLocaleChange(locale));

  // Theme
  const userTheme = userInfo.preferences.theme === "Light" ? "light" : "dark";
  localStorage.setItem(THEME, userTheme);
  store.dispatch(onSwitchTheme(userTheme));

  // Save company's information in local storage
  const companyInfo = userInfo.company;
  localStorage.setItem(COMPANY_ID, window.btoa(companyInfo.pk));
  localStorage.setItem(COMPANY_NAME, companyInfo.name);
  localStorage.setItem(COMPANY_TYPE, companyInfo.type);
  localStorage.setItem(
    FIRST_PRODUCTION_DATE,
    companyInfo.first_production_date
  );
  localStorage.setItem(STAGES, JSON.stringify(companyInfo.stages));
  localStorage.setItem(TODO_LIST, JSON.stringify(companyInfo.todo_list));
  localStorage.setItem(EMPLOYEES, JSON.stringify(companyInfo.employees));
  localStorage.setItem(ASSETS, JSON.stringify(companyInfo.assets));

  return {
    companyType: companyInfo.type,
    department: userInfo.department,
    biAccess: userInfo.company.bi_access,
    stages: companyInfo.stages,
    todo_list: companyInfo.todo_list,
    employees: companyInfo.employees,
    assets: companyInfo.assets,
  };
};

export const signIn = createAsyncThunk(
  "auth/login",
  async (data, { rejectWithValue }) => {
    const { email, password } = data;
    try {
      // Make a call to the API with the email and password
      // to get the refresh and access tokens
      const response = await AuthService.login({ email, password });

      localStorage.setItem(ACCESS_TOKEN, response.access);
      localStorage.setItem(REFRESH_TOKEN, response.refresh);

      // Get user information and save it using the reusable function
      const userInfo = await UserService.getUserInfo();
      const savedData = saveUserInfoToLocalStorage(userInfo);

      return {
        access: response.access,
        refresh: response.refresh,
        ...savedData,
      };
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const signOut = createAsyncThunk("auth/logout", async () => {
  store.dispatch(resetTheme());
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authenticated: (state, action) => {
      state.loading = false;
      state.access_token = action.payload.access;
      state.refresh_token = action.payload.refresh;
      state.redirect = "/";
    },
    updateUserData: (state, action) => {
      state.companyType = action.payload.companyType;
      state.department = action.payload.department;
      state.biAccess = action.payload.biAccess;
      state.stages = action.payload.stages;
      state.todo_list = action.payload.todo_list;
      state.employees = action.payload.employees;
      state.assets = action.payload.assets;
    },
    updateTaskList: (state, action) => {
      const updatedTask = action.payload;

      const updatedTasks = state.todo_list.tasks.map((stage) => {
        if (stage.stage_id === updatedTask.stage_id) {
          const taskIndex = stage.tasks.findIndex(
            (task) => task.id === updatedTask.id
          );

          if (taskIndex !== -1) {
            // Task exists, update its values
            stage.tasks[taskIndex] = {
              ...stage.tasks[taskIndex],
              ...updatedTask,
            };
          } else {
            // Task does not exist, add to the beginning
            stage.tasks = [updatedTask, ...stage.tasks];
          }

          return { ...stage }; // Return updated stage
        }
        return stage; // Return stage as is
      });

      // Update the state with the new tasks
      state.todo_list.tasks = updatedTasks;

      // Persist the updated todo_list to localStorage
      localStorage.setItem(TODO_LIST, JSON.stringify(state.todo_list));
    },
    removeTask: (state, action) => {
      const taskToRemove = action.payload; // Could be either a task object or an ID (number)

      const updatedTasks = state.todo_list.tasks.map((stage) => {
        return {
          ...stage,
          tasks: stage.tasks.filter((task) => task.id !== taskToRemove.id),
        };
      });

      // Update the state with the new tasks
      state.todo_list.tasks = updatedTasks;

      // Persist the updated todo_list to localStorage
      localStorage.setItem(TODO_LIST, JSON.stringify(state.todo_list));
    },

    updateInsightList: (state, action) => {
      const updatedInsight = action.payload;

      const updatedInsights = state.todo_list.insights.map((stage) => {
        if (stage.stage_id === updatedInsight.stage_id) {
          const insightIndex = stage.insights.findIndex(
            (insight) => insight.pk === updatedInsight.pk
          );

          if (insightIndex !== -1) {
            // insight exists, update its values
            stage.insights[insightIndex] = {
              ...stage.insights[insightIndex],
              ...updatedInsight,
            };
          } else {
            // insight does not exist, add to the beginning
            stage.insights = [updatedInsight, ...stage.insights];
          }

          return { ...stage }; // Return updated stage
        }
        return stage; // Return stage as is
      });

      // Update the state with the new tasks
      state.todo_list.insights = updatedInsights;

      // Persist the updated todo_list to localStorage
      localStorage.setItem(TODO_LIST, JSON.stringify(state.todo_list));
    },

    removeInsight: (state, action) => {
      const insightToRemove = action.payload; // Could be either a task object or an ID (number)

      const updatedInsights = state.todo_list.insights.map((stage) => {
        return {
          ...stage,
          insights: stage.insights.filter(
            (insight) => insight.pk !== insightToRemove.pk
          ),
        };
      });

      // Update the state with the new tasks
      state.todo_list.insights = updatedInsights;

      // Persist the updated todo_list to localStorage
      localStorage.setItem(TODO_LIST, JSON.stringify(state.todo_list));
    },

    showAuthMessage: (state, action) => {
      state.message = action.payload;
      state.showMessage = true;
      state.loading = false;
    },
    hideAuthMessage: (state) => {
      state.message = "";
      state.showMessage = false;
    },
    signOutSuccess: (state) => {
      state.loading = false;
      state.access_token = null;
      state.refresh_token = null;
      state.redirect = "/";
    },
    showLoading: (state) => {
      state.loading = true;
    },
    signInSuccess: (state, action) => {
      state.loading = false;
      state.access_token = action.payload.access;
      state.refresh_token = action.payload.refresh;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.loading = true;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.loading = false;
        state.access_token = action.payload.access;
        state.refresh_token = action.payload.refresh;
        state.companyType = action.payload.companyType;
        state.department = action.payload.department;
        state.biAccess = action.payload.biAccess;
        state.stages = action.payload.stages;
        state.todo_list = action.payload.todo_list;
        state.employees = action.payload.employees;
        state.assets = action.payload.assets;
        state.redirect = "/";
      })
      .addCase(signIn.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signOut.fulfilled, (state) => {
        state.loading = false;
        localStorage.clear();
        state.access_token = null;
        state.stages = null;
        state.companyType = null;
        state.department = null;
        state.todo_list = null;
        state.employees = null;
        state.assets = null;
        state.refresh_token = null;
        state.redirect = "/";
      })
      .addCase(signOut.rejected, (state) => {
        state.loading = false;
        state.access_token = null;
        state.refresh_token = null;
        state.redirect = "/";
      });
  },
});

export const {
  authenticated,
  updateTaskList,
  updateInsightList,
  updateUserData,
  removeTask,
  removeInsight,
  showAuthMessage,
  hideAuthMessage,
  signOutSuccess,
  showLoading,
  signInSuccess,
} = authSlice.actions;

export default authSlice.reducer;
