export const COMPANY_ID = "company_id";
export const COMPANY_NAME = "company_name";
export const COMPANY_TYPE = "company_type";

export const INITIALIZE_ASSET_LIST = "initialize_asset_list";
export const UPDATE_ASSET_LIST = "update_asset_list";
export const CLEAR_ASSET_LIST = "clear_asset_list";

export const FIRST_PRODUCTION_DATE = "first_production_date";
export const STAGES = "stages";
export const TODO_LIST = "todo_list";
export const EMPLOYEES = "employees";
export const ASSETS = "assets";
