import {
  ADD_NEW_CALLBACK,
  REMOVE_CALLBACK,
  CONNECT_SUCCESS,
  DISCONNECT_SUCCESS,
} from "../constants/Websocket";

const initState = {
  instances: {}, // Store multiple WebSockets (assetOverview and todoList)
  callbacks: {},
};

const websocket = (state = initState, action) => {
  switch (action.type) {
    case CONNECT_SUCCESS:
      return {
        ...state,
        instances: {
          ...state.instances,
          [action.instance.type]: action.instance, // Track each WebSocket separately
        },
      };

    case ADD_NEW_CALLBACK:
      const { command, callback } = action.payload;
      return {
        ...state,
        callbacks: {
          ...state.callbacks,
          [command]: callback,
        },
      };

    case REMOVE_CALLBACK:
      const { commandName } = action.payload;
      const newCallbacks = { ...state.callbacks };
      delete newCallbacks[commandName];

      return {
        ...state,
        callbacks: newCallbacks,
      };

    case DISCONNECT_SUCCESS:
      const newInstances = { ...state.instances };
      delete newInstances[action.payload.type]; // Remove only the disconnected WebSocket

      return {
        ...state,
        instances: newInstances,
      };

    default:
      return state;
  }
};

export default websocket;
