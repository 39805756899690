import { t } from "i18next";
import { message, notification } from "antd";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "constants/AuthConstant";
import store from "store";
import {
  signOutSuccess,
  authenticated,
  saveUserInfoToLocalStorage,
} from "store/slices/authSlice";
import AuthService from "services/auth/AuthService";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { updateUserData } from "store/slices/authSlice";
import UserService from "services/api/UserService";

// API Request interceptor for all api services and login service
export function requestInterceptor(service) {
  service.interceptors.request.use(
    (config) => {
      const jwtToken = localStorage.getItem(ACCESS_TOKEN) || null;

      if (jwtToken) {
        config.headers["Authorization"] = "Token " + jwtToken;
        config.headers["Time-Zone"] =
          Intl.DateTimeFormat().resolvedOptions().timeZone;
      }

      return config;
    },
    (error) => {
      // Do something with request error here
      notification.error({
        message: "Error",
      });
      Promise.reject(error);
    }
  );
}

// Function to determine if the success message should be displayed
const doNotDisplaySuccessMessage = (response) => {
  return (
    (response.config.url === "/users/update_preferences/" &&
      response.config.data.includes("language")) ||
    response.config.url === "/token/"
  );
};

// API response interceptor
export function responseInterceptor(service) {
  service.interceptors.response.use(
    // Everything is fine, return the response.
    (response) => {
      // For PUT, POST, DELETE requests, display a success message.
      if (["put", "post", "delete"].includes(response.config.method)) {
        if (doNotDisplaySuccessMessage(response)) {
          return response.data;
        }

        message.success(
          response.data.message ? t("response." + response.data.message) : "",
          2
        );
      }
      return response.data;
    },

    // An error occurred, display an error message
    async (error) => {
      const originalConfig = error.config;
      const originalConfigUrl = originalConfig.url;
      const responseStatus = error.response.status;

      // If the error is 401, the token is invalid or expired, and the user must log in again.
      if (responseStatus === 401 && originalConfigUrl !== "/token/") {
        // Set the _retry property to true to indicate that the request should be retried.
        originalConfig._retry = true;

        try {
          // Call the refreshToken function and await its result.
          const rs = await AuthService.refreshToken(); // Ensure this is awaited

          // Assuming the response contains the new access token in `rs.access`
          const accessToken = rs.access;

          // Store the new access token in localStorage
          localStorage.setItem(ACCESS_TOKEN, accessToken);

          // **Update the Redux store with the new token**
          store.dispatch(
            authenticated({
              access: accessToken,
              refresh: localStorage.getItem(REFRESH_TOKEN),
            })
          );

          // Get user information and save it using the reusable function
          const userInfo = await UserService.getUserInfo();
          const savedData = saveUserInfoToLocalStorage(userInfo);

          // Dispatch action to update Redux store with new saved data
          store.dispatch(updateUserData(savedData));

          // Return the original request with the new access token
          return service(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      } else if (responseStatus === 401 && originalConfigUrl === "/token/") {
        localStorage.removeItem(ACCESS_TOKEN);
        localStorage.removeItem(REFRESH_TOKEN);

        // Dispatch the signOutSuccess action to update the state of the application
        store.dispatch(signOutSuccess());

        //Display an error message
        message.error(t("response.error.auth"), 3);
      }
      // Handle 404 status code: Redirect to /not found page
      else if (responseStatus === 404) {
        window.location.href = `${APP_PREFIX_PATH}/not-found`; // Redirect using window.location
      }

      // Handle 500 status code: Redirect to /server-error page
      else if (responseStatus === 500) {
        window.location.href = `${APP_PREFIX_PATH}/server-error`; // Redirect to server-error page
      }

      // For other errors, display the error message based on the code returned by the server.
      else {
        message.error(
          error.response.data.message
            ? responseStatus === 408
              ? t("response.error.timeout")
              : responseStatus === 406
              ? t("response." + error.response.data.message, {
                  field: t(error.response.data.field).toLocaleLowerCase(),
                })
              : t("response." + error.response.data.message)
            : t("response.error.default"),
          3
        );
      }

      return Promise.reject(error);
    }
  );
}
