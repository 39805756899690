import { WS_ENDPOINT_URL } from "configs/AppConfig";
import { ACCESS_TOKEN } from "constants/AuthConstant";
import { CONNECT, DISCONNECT, NEW_MESSAGE } from "../constants/Websocket";
import {
  connectSuccess,
  disconnectSuccess,
  newMessage,
} from "../actions/Websocket";
import {
  all,
  call,
  cancel,
  cancelled,
  delay,
  fork,
  putResolve,
  select,
  take,
  takeEvery,
} from "redux-saga/effects";
import { END, eventChannel } from "redux-saga";
import AuthService from "services/auth/AuthService";

// Function to get reducer state in sagas
export const getState = (state) => state.websocket;
export const getUserState = (state) => state.user;

export function* socketNewMessage() {
  yield takeEvery(NEW_MESSAGE, function* ({ payload, messageType }) {
    try {
      // Parse new message
      const parsedData = JSON.parse(payload);
      const command = parsedData.command;

      // Get the store
      const store = yield select(getState);

      // Get the callbacks dictionary
      let callbacks = store.callbacks;
      if (Object.keys(callbacks).length === 0) {
        return;
      }

      // Live update currently for capability or operation or batch in asset overview component.
      if (
        command === "capabilityUpdate" ||
        command === "operationUpdate" ||
        command === "batchUpdate" ||
        command === "todoListUpdate"
      ) {
        if (command === "todoListUpdate") {
          // Call the callback function
          callbacks[command](
            parsedData.data,
            parsedData.action,
            parsedData.model
          );
        } else {
          const userStore = yield select(getUserState);
          let assets = userStore.assets;

          // Call the callback function
          callbacks[command](assets, parsedData.data);
        }
      }
    } catch (err) {
      console.log(
        `Error parsing new message of type: ${messageType} in WebSocket:`,
        err
      );
    }
  });
}

// Use this to actually throw exceptions, allows for easier debugging.
const dispatch = putResolve;

function createWebSocketConnection(subPath) {
  return new Promise((resolve, reject) => {
    const path = `${WS_ENDPOINT_URL}websocket/${subPath}/?source=front-end&token=${localStorage.getItem(
      ACCESS_TOKEN
    )}`;
    const socket = new WebSocket(path, []);

    socket.onopen = function () {
      resolve(socket);
    };

    socket.onerror = function (evt) {
      reject(evt);
    };
  });
}

function createSocketChannel(socket) {
  return eventChannel((emit) => {
    socket.onmessage = (event) => {
      emit(event.data);
    };

    socket.onclose = () => {
      emit(END);
    };

    const unsubscribe = () => {
      socket.onmessage = null;
    };

    return unsubscribe;
  });
}

function* listenForSocketMessages(subPath) {
  let socket;
  let socketChannel;
  let retryCount = 0;
  const maxRetries = 3; // Maximum retries before showing an error (not logging out)

  try {
    socket = yield call(createWebSocketConnection, subPath);
    socketChannel = yield call(createSocketChannel, socket);

    // Tell the application that we have a connection
    yield dispatch(
      connectSuccess({
        type: subPath,
        payload: {
          type: subPath,
          instance: socket,
        },
      })
    );

    while (true) {
      // Wait for a message from the channel
      const payload = yield take(socketChannel);

      // Dispatch the received message
      yield dispatch(newMessage({ type: subPath, payload }));
    }
  } catch (error) {
    console.log(`Error connecting to WebSocket (${subPath}):`, error);
  } finally {
    if (yield cancelled()) {
      console.log(`WebSocket connection for ${subPath} was terminated`);
      if (socketChannel && socket) {
        socketChannel.close();
        socket.close();
      }
    } else {
      console.log(`WebSocket (${subPath}) disconnected. Retrying...`);

      while (retryCount < maxRetries) {
        // Wait before retrying
        yield delay(2000 * (retryCount + 1));

        try {
          console.log("Refreshing token...");

          const rs = yield call(AuthService.refreshToken);

          const accessToken = rs.access;

          localStorage.setItem(ACCESS_TOKEN, accessToken);

          // Reset retry count
          retryCount = 0;

          console.log("Token refreshed successfully.");

          // Try reconnecting
          yield call(listenForSocketMessages, subPath);

          return;
        } catch (_error) {
          console.log(
            `WebSocket reconnection attempt ${retryCount + 1} failed`
          );

          // Increment the retry count
          retryCount++;
        }
      }

      console.log("WebSocket reconnection failed after multiple attempts.");
    }
  }
}

export function* wsConnect() {
  yield takeEvery(CONNECT, function* ({ payload }) {
    if (!payload || !["todoList", "assetOverview"].includes(payload.type)) {
      console.log(
        `Invalid payload type: ${payload.type} for WebSocket connection`
      );
      return;
    }

    // Object to store socket tasks
    const socketTasks = {};

    // Check if a WebSocket for this type already exists
    if (!socketTasks[payload.type]) {
      socketTasks[payload.type] = yield fork(
        listenForSocketMessages,
        payload.type === "todoList" ? "todo-list" : "asset-overview"
      );
    }

    while (true) {
      const { payload: disconnectPayload } = yield take(DISCONNECT);

      // If a specific type is provided, cancel only that WebSocket
      if (disconnectPayload?.type && socketTasks[disconnectPayload.type]) {
        yield cancel(socketTasks[disconnectPayload.type]);
        delete socketTasks[disconnectPayload.type];

        yield dispatch(disconnectSuccess({ type: disconnectPayload.type }));
      }

      // If no WebSockets are left, break the loop
      if (Object.keys(socketTasks).length === 0) {
        break;
      }
    }
  });
}

export default function* rootSaga() {
  yield all([fork(wsConnect), fork(socketNewMessage)]);
}
